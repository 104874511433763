.grid {
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Nunito Sans", sans-serif;

  form {
    width: 100%;
  }

  .formItem {
    color: #101724 !important;
    font-size: 15px !important;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      font-size: 14px;
    }

    label {
      height: 18px !important;
    }

    input {
      width: 100%;
      height: 40px;
      border: 1px solid #959595;
      padding: 0 !important;
      padding-left: 15px !important;
      background: #fff;
      border-radius: 0;
      outline: none !important;

      &:focus {
        border-color: #22b748 !important;
        box-shadow: none !important;
      }

      &:hover {
        border-color: #22b748 !important;
      }
    }

    input[type="checkbox"] {
      width: 20px !important;
      height: 20px !important;
    }

    &__checkbox {
      margin: 16px auto 0;
      display: flex;

      p {
        margin-left: 10px;
      }
    }
  }

  .formFooter {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #101724 !important;

    .submit {
      background-color: #22b748;
      width: 100%;
      height: 50px;
      color: #fff;
      font-size: 16px;
      line-height: 19px;
      margin: 20px auto 10px;
      cursor: pointer;
      border-radius: unset;
      border: 1px solid black;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        background-color: #24c442;
      }
    }
  }

  .link {
    color: #22b748;
    font-size: 14px;
    line-height: 17px;
    display: block;
    width: 100%;
    text-align: right;

    &:hover {
      color: #22b748;
    }

    &__inline {
      display: inline-block;
      width: auto;
    }
  }
}
