@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./sass_modules/all";
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600|Open+Sans:400,600,700&display=swap");

.activeTab {
  @apply bg-dark-blue text-white;
}
.unActiveTab {
  @apply bg-white text-dark-gray;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Nunito Sans", sans-serif;
}

#root,
.change-locale,
.ant-layout {
  height: 100%;
}

.ant-layout-content {
  min-height: fit-content !important;
}

.ant-popover-inner-content {
  padding: 5px 9px;
}

.navigationBackgroundAndColor {
  color: #fff;
}

.ant-select-dropdown-menu-item-active {
  background: rgba(#22b748, 0.2) !important;
}

.ant-select-dropdown {
  border-radius: 0 !important;
}

.ant-select-dropdown-menu-item-active {
  border-radius: 0 !important;
}

.ant-layout.ant-layout-has-sider .ant-layout-sider.simplified-version {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;
}

.landingPageCustomAntSelect {
  .ant-select-selection {
    border-radius: 0 !important;
    height: 40px;
    border: 1px solid #959595;

    &:hover {
      border-color: #22b748 !important;
    }
  }

  .ant-select-selection--single .ant-select-selection__rendered {
    height: 100%;
  }

  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus {
    box-shadow: none !important;
    border-color: #22b748 !important;
  }
}

.headerPartnerSubmenu {
  position: fixed;

  .ant-menu {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 275px;
    border-radius: 0 !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: inherit;
  }

  .ant-menu-item-selected {
    color: inherit;
  }

  .ant-menu-item:hover {
    color: inherit;
  }

  .ant-menu-item {
    height: 24px;
    margin-bottom: 10px;
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.customAnt {
  &--offersView {
    .ant-btn {
      width: 40px;
      height: 40px;
      border-radius: 0;
      background: $green_01;
      box-shadow: 0px 0px 2px rgba(13, 34, 66, 0.15);
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover,
      &:focus {
        border-color: $green_01;
      }
    }

    .ant-pagination-item-active {
      font-weight: bold;
      border: unset;
      color: #22b74b !important;
      background-color: unset !important;
    }

    .ant-pagination-item {
      border: unset;
      color: #22b74b !important;
      background-color: unset !important;

      &:hover a {
        color: #22b74b !important;
      }
    }

    .ant-pagination-item-active a {
      color: #22b74b !important;
      background-color: unset !important;
      border: unset;

      &:hover {
        color: #22b74b !important;
      }
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border: unset;
      background-color: unset;
      font-size: 30px;
    }

    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-pagination-next:focus .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-prev:focus .ant-pagination-item-link {
      color: #22b74b !important;
    }
  }

  &--copyIcon {
    display: flex;
    justify-content: space-between;

    .ant-btn {
      background: transparent;
      border: none;
      box-shadow: none;

      &:hover {
        color: $green_01;
      }
    }
  }

  &--moreOptsIcon {
    .ant-btn-icon-only {
      border: none;
      font-size: 20px;
    }

    .ant-btn {
      background-color: transparent;
      box-shadow: none;

      &:hover,
      &:focus {
        color: $green_01;
        border-color: none;
      }
    }
  }

  &--productDetailsTable {
    .ant-table-thead {
      background: #edf1ff;
    }

    .ant-table-row:nth-child(even) {
      background: #f7f8ff;
    }

    .ant-table-row:first-child {
      font-weight: bold;
    }

    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
      border: none;
    }

    .ant-table-thead > tr > th {
      &:first-child {
        background-color: #e8e8e8 !important;

        .ant-table-column-sorters::before {
          background-color: #e8e8e8 !important;
        }
      }
    }

    .ant-table table {
      border: none !important;
      border-radius: 0 !important;
    }

    .ant-table-small,
    .ant-table-small.ant-table-bordered .ant-table-content {
      border: none;
      border-radius: 0;
    }

    .ant-table tbody > tr {
      height: 60px;
    }

    // Ukrycie current page number
    .ant-pagination-item,
    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
      display: none;
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item-link,
    .ant-pagination-item-link {
      font-size: 16px;
      min-height: 32px;
      min-width: 32px;
      border-radius: unset;
      border-color: $grey_02;
      background: #ffffff;
      box-shadow: 0px 0px 2px rgba(13, 34, 66, 0.15);

      &:hover {
        color: $green_01;
        border-color: $green_01 !important;
      }
    }

    .ant-pagination-item-link {
      margin: unset;

      .anticon {
        margin-top: 8px;
      }
    }

    .anticon {
      &:hover {
        color: $green_01 !important;
      }
    }

    .ant-table-pagination.ant-pagination {
      position: relative;
      // top: -50px;
    }
  }

  &--expandedPostbackTable {
    padding: unset;

    .ant-table-thead {
      background: #edf1ff;
    }

    .ant-table-row:nth-child(even) {
      background: #f7f8ff;
    }

    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
      border: unset;
    }

    .ant-table table {
      border-radius: unset;
    }

    .ant-table-small,
    .ant-table-small.ant-table-bordered .ant-table-content {
      border: none;
      border-radius: 0;
    }

    .ant-table tbody > tr {
      height: 15px;
    }

    .ant-empty-normal {
      margin: 0px 0;
      color: rgba(0, 0, 0, 0.25);
    }
  }

  &--financeTable {
    .ant-table-thead {
      background: #edf1ff;
    }

    .ant-table-row:nth-child(even) {
      background: #f7f8ff;
    }

    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
      border: none;
    }

    .ant-table table {
      border: none !important;
      border-radius: 0 !important;
    }

    .ant-table-small,
    .ant-table-small.ant-table-bordered .ant-table-content {
      border: none;
      border-radius: 0;
    }

    .ant-table tbody > tr {
      height: 60px;
    }

    // Ukrycie current page number
    .ant-pagination-item,
    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
      display: none;
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item-link,
    .ant-pagination-item-link {
      font-size: 16px;
      min-height: 32px;
      min-width: 32px;
      border-radius: unset;
      border-color: $grey_02;
      background: #ffffff;
      box-shadow: 0px 0px 2px rgba(13, 34, 66, 0.15);

      &:hover {
        color: $green_01;
        border-color: $green_01 !important;
      }
    }

    .ant-pagination-item-link {
      margin: unset;

      .anticon {
        margin-top: 8px;
      }
    }

    .anticon {
      &:hover {
        color: $green_01 !important;
      }
    }

    .ant-table-pagination.ant-pagination {
      position: relative;
      top: 10px;
      float: right;
      margin: unset;
    }
  }

  &--payoutAccessList {
    .ant-checkbox-inner {
      width: 22px;
      height: 22px;
    }

    .ant-switch {
      margin-top: 5px;
    }

    .ant-form label {
      font-size: 14px;
      padding-top: 6px;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      margin-left: 2px;
    }

    .ant-list-grid .ant-col > .ant-list-item {
      margin-bottom: 0px;
    }

    .Styles_grid_col_7__236AQ {
      padding: 3px 0px 0px 0px;
    }

    .ant-divider-horizontal {
      margin: 1px;
    }
  }

  &--ordersDetailsTable {
    .ant-table-thead {
      background: #edf1ff;
    }

    .ant-table-row:nth-child(even) {
      background: #b0b0b4;
    }

    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
      border: none;
    }

    .ant-table table {
      border: none !important;
      border-radius: 0 !important;
    }

    .ant-table-small,
    .ant-table-small.ant-table-bordered .ant-table-content {
      border: none;
      border-radius: 0;
    }

    .ant-table tbody > tr {
      height: 60px;
    }
  }

  &--campaignsFilters {
    .ant-select-selection,
    .ant-input,
    .ant-input-affix-wrapper,
    .ant-select {
      width: 133px;
      margin-right: 20px;
    }

    ::placeholder,
    .ant-select-selection__placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $grey_03;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $grey_03;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $grey_03;
    }

    .ant-select-arrow {
      color: $grey_03;
    }
  }

  &--newsSelect {
    #instances {
      width: 133px !important;
      height: 34px;
    }

    .ant-select-selection,
    .ant-input {
      color: #339fdd !important;
      border-color: #bdbaba !important;
      border-radius: 3px !important;
    }

    .ant-select-selection:hover,
    .ant-select-selection:focus,
    .ant-select-selection:active,
    .ant-input:hover,
    .ant-input:focus,
    .ant-input:active {
      border-color: #339fdd !important;
      box-shadow: none;
    }

    .ant-select-open .ant-select-selection {
      box-shadow: none;
    }

    .ant-btn {
      height: 32px;
      border-radius: 0;
      border: 1px solid;
      background: $green_01;
      box-shadow: 0px 0px 2px rgba(13, 34, 66, 0.15);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 23px !important;

      &:hover,
      &:focus {
        border-color: $green_01;
      }

      &:disabled {
        background-color: unset;
        &:hover,
        &:focus {
          border-color: red;
        }
      }
    }
  }

  &--mainHeaderUser {
    position: fixed;
    width: 205px;

    @media (max-width: 620px) {
      padding-left: 20px !important;
    }

    &.ant-popover-placement-bottomRight
      > .ant-popover-content
      > .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      padding: 10px 15px;
      border-radius: 0;
      background: $white;
    }

    .after img {
      transition: transform 0.2s linear;
    }

    &.ant-popover-open {
      .after {
        img {
          transform: rotate(180deg);
        }
      }
    }

    .userBox__profile {
      margin-bottom: 22px;

      a {
        color: inherit;

        i {
          margin-right: 8px;
        }
      }
    }
  }

  &--managerPopover {
    position: fixed;
    width: 206px;

    &.ant-popover-placement-bottomLeft
      > .ant-popover-content
      > .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      padding: 10px 15px;
      border-radius: 0;
      background: $blue01;
    }

    .ant-popover-inner-content {
      padding: 0;
    }

    button {
      background: transparent;
      box-shadow: none;
      border: none;
      color: inherit;
      width: 100%;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }
  }

  &--regForm {
    @extend .landingPageCustomAntSelect;

    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #22b748;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #22b748;
      border-color: #22b748;
    }

    .form-control-column-label {
      height: 18px;
      margin-bottom: 8px;
    }
  }

  &--surveyForm {
    @extend .landingPageCustomAntSelect;
  }

  &--userEditForm {
    width: 100%;
    @extend .landingPageCustomAntSelect;

    .ant-select {
      width: 100%;
    }

    .ant-select-selection {
      height: 36px;
      width: 100%;
    }
  }

  &--newOrderForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    @extend .landingPageCustomAntSelect;

    .ant-select {
      width: 100%;
    }

    .ant-select-selection {
      height: 36px;
      width: 100%;
    }

    label,
    input,
    p {
      width: 100%;
    }

    label {
      display: flex;
      white-space: nowrap;
    }

    .ant-input {
      width: 100%;
      border: 1px solid #898c90;
      border-radius: 0px;
      box-shadow: none;
      padding: 5px;
      height: 36px;

      &:focus,
      &:hover {
        outline: none;
        border: 1px solid #22b74b !important;
      }

      &:disabled {
        background: #f5f5f5;
        cursor: not-allowed;
      }
    }
  }

  &--langPicker {
    .ant-select {
      color: #fff;
    }

    .ant-select-selection {
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;

      &:focus {
        box-shadow: none !important;
      }
    }

    .ant-select-arrow {
      display: none;
    }
  }

  &--headerLangPicker {
    width: 100%;
    margin-top: 3px;
    justify-content: center;

    img {
      margin-right: 5px;
    }

    p {
      font-weight: 600;
    }

    @extend .customAnt--langPicker;
    height: 100%;
    display: flex;
    align-items: center;

    .ant-select {
      color: #000;
      width: 100% !important;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .ant-select-selection {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;

      &--single {
        width: 100% !important;
      }
    }
  }

  &--landingNav {
    .ant-anchor-link {
      padding: 7px 0;
    }

    .ant-anchor-wrapper {
      background-color: inherit;
      margin-left: 0;
      padding-left: 0;
    }

    .ant-anchor-ink::before {
      content: none;
    }

    .ant-anchor-link-title {
      font-size: 16px;
      color: #fff;
    }
  }

  &--newsList {
    .ant-pagination-item-active {
      font-weight: bold;
      border: unset;
      color: #22b74b !important;
      background-color: unset !important;
    }

    .ant-pagination-item {
      border: unset;
      color: #22b74b !important;
      background-color: unset !important;

      &:hover a {
        color: #22b74b !important;
      }
    }

    .ant-pagination-item-active a {
      color: #22b74b !important;
      background-color: unset !important;
      border: unset;

      &:hover {
        color: #22b74b !important;
      }
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border: unset;
      background-color: unset;
      font-size: 30px;
    }

    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-pagination-next:focus .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-prev:focus .ant-pagination-item-link {
      color: #22b74b !important;
    }
  }

  &--menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;

    .link_logo {
      img {
        margin-top: 25px;
        margin-bottom: 25px;
      }

      div {
        text-align: center;
        color: white;
        margin: 5px 0 28px 0;
        font-size: 12px;
      }
    }

    .ant-menu-dark {
      @extend .navigationBackgroundAndColor;

      .ant-menu-inline.ant-menu-sub {
        box-shadow: none;
        background: inherit;
      }

      .ant-menu-item {
        border-left: 4px solid transparent;
      }

      .ant-menu-item > a {
        color: #fff;
        display: flex;
        align-items: center;
      }

      .ant-menu-item-selected {
        background-color: #2c3657;
        border-left: 4px solid #1d9fd9 !important;
      }
    }

    .ant-menu-inline .ant-menu-item {
      font-size: 15px;
      font-family: "Nunito Sans", sans-serif;
      font-weight: 300;
      display: flex;
      align-items: center;
      border-left: 4px solid transparent;
    }
  }

  &--menuItem {
    .ant-menu-item,
    .ant-menu-submenu-title {
      width: 204px;
      background-color: unset;
      line-height: 62px;
    }

    .ant-menu-horizontal > .ant-menu-submenu {
      background-color: rgb(248, 250, 252);
    }
  }

  &--radioButton {
    .ant-radio-button-wrapper {
      border: unset;
      background-color: unset;
      height: 19px;
      padding: 0;
      margin: 0 7px;

      font-family: "Nunito Sans", sans-serif;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: $grey_02;

      &:before {
        content: none;
      }
    }

    .ant-radio-button-wrapper-checked {
      color: #29c05c;
    }

    .ant-radio-button-wrapper:hover {
      color: #29c05c;
    }

    .ant-radio-button-wrapper-checked:focus-within {
      outline: unset;
    }

    .ant-radio-button-wrapper::before {
      background-color: unset;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      box-shadow: unset;
    }

    .ant-radio-group {
      height: 19px;
      // border: 1px solid red;
    }

    .ant-radio-button-wrapper span:last-child {
      height: 19px;
    }
  }

  &--tabPanel {
    font-family: "Nunito Sans", sans-serif;

    .ant-tabs-nav .ant-tabs-tab {
      font-size: 15px;
      line-height: 20px;
      color: #101724;
      padding: 0;
      margin: 0;
      margin-right: 20px;
      padding-bottom: 6px;

      &:last-child {
        margin-right: 0;
      }

      &-disabled {
        color: rgba(#000, 0.25);

        &:hover {
          color: rgba(#000, 0.25) !important;
        }
      }
    }

    .ant-tabs-nav .ant-tabs-tab-active {
      color: #22b74b;
    }

    .ant-tabs-ink-bar {
      background-color: #22b74b;
      width: 35px !important;
    }

    .ant-tabs-nav .ant-tabs-tab:hover {
      color: #22b74b;
    }

    .ant-tabs-ink-bar-animated {
      background-color: #22b74b;
    }

    .ant-tabs-bar {
      margin: 0;
    }
  }

  &--leadsFilters {
    margin-bottom: 5px;

    .ant-divider {
      margin: 13px 0px 10px;
    }

    .grid_col_3 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 12px;

      @media (max-width: 850px) {
        grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
      }
    }

    .grid_col_4 {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 12px;

      @media (max-width: 850px) {
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
      }
    }

    .grid_col_5 {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      column-gap: 12px;

      @media (max-width: 850px) {
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
      }
    }
    // => Fields styles

    .activeFilterStyle {
      .ant-select-selection,
      .ant-input {
        color: black;
        border: 1px solid #0e82fa;
        color: #0e82fa;

        &:hover {
          color: #0e82fa !important;
          font-size: 102%;
          border: 1px solid #0e82fa !important;
        }
      }
    }

    .ant-calendar-picker {
      width: 49%;
    }

    .ant-calendar-picker-input {
      color: black;
      border: 1px solid #0e82fa;
      color: #0e82fa;

      &:hover {
        color: #0e82fa;
        font-size: 102%;
        border: 1px solid #0e82fa !important;
      }
    }

    .select_button_style {
      width: 100%;
      margin-top: 20px;

      &:hover {
        color: #0e82fa;
        font-size: 105%;
        font-weight: bold;
        border: 1px solid #0e82fa;
      }

      &:disabled {
        color: black;
        border: 1px solid #0e82fa;
      }
    }

    .reset_button_style {
      width: 100%;
      padding: unset;
      margin: unset;

      &:hover {
        border: solid 1px red;
        color: red;
        font-size: 105%;
        font-weight: bold;
      }
    }

    .submit_button_style {
      width: 100%;
      color: white;
      background-color: #0e82fa;

      &:hover {
        background-color: #3796fb;
        font-size: 105%;
        font-weight: bold;
      }

      @media (max-width: 450px) {
        margin-bottom: 10px;
      }
    }

    .tomezoneStyle {
      width: 120px;

      @media (max-width: 500px) {
        width: 100%;
      }

      .ant-select-selection {
        color: black;
        border: 1px solid #0e82fa;
        color: #0e82fa;

        &:hover {
          color: #0e82af;
          font-size: 102%;
          color: #0e82fa;
          border: 1px solid #0e82fa !important;
        }
      }
    }
  }

  &--registrationLinkForm {
    .grid {
      display: grid;
      grid-template-columns: auto 36px;
      column-gap: 5px;
    }

    .copy_button {
      margin-top: 37px;
    }

    .ant-input {
      width: 100%;
      outline: none;
      box-shadow: none;
      border: 1px solid $grey_02;
      border-radius: unset;
      height: 36px;
    }

    label {
      color: $grey_02;
    }

    .ant-btn {
      height: 36px;
      width: 36px;
      border: 1px solid $grey_01;
      border-radius: unset;

      &:hover {
        color: rgba(34, 183, 72, 1);
        border: 1px solid rgba(34, 183, 72, 1);
      }
    }

    p {
      margin-top: 20px;
      color: $grey_02;

      b {
        color: black;
      }
    }
  }
}

// slick slider
.customSlick {
  &--landing {
    .slick-dots {
      bottom: -50px;
    }

    .slick-prev,
    .slick-next {
      top: auto;
      bottom: -70px;
      z-index: 1000;
      transform: none;
      display: flex;
      align-items: center;

      &::before {
        content: none;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }

    .slick-prev {
      left: 50%;
      transform: translate(-800%, -100%);
    }

    .slick-next {
      right: 50%;
      transform: translate(800%, -100%);
    }

    .slick-dots {
      max-width: 100%;
      width: 380px;
      left: 50%;
      transform: translateX(-50%);
    }

    .slick-dots li {
      margin: 0;
    }

    .slick-dots li button:before {
      color: #1db146;
    }

    .slick-dots li.slick-active button:before {
      color: #1db146;
      font-size: 8px;
    }
  }
}

// product description in parter/offers/id
.productDetailsDescription {
  margin-top: 46px;

  section,
  h6,
  p,
  ol,
  ol > li {
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: #4d4d4d;
  }

  section {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    h6 {
      font-size: 14px;
      line-height: 17px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #101724;
    }
  }
}

.addNew {
  margin-bottom: 5px;
  width: 180px;
}

.globalSearch {
  width: 180px;
}

.marginTop {
  margin-top: 5px;
}

.marginRigth {
  margin-right: 10px;
}

.marginLeft {
  margin-left: 10px;
}

.marginBottom {
  margin-bottom: 10px;
}

.formStyle {
  margin-bottom: 40px;
}

.marginAuto {
  margin: auto;
}

.formMarginTop {
  margin-top: 15px;
}

.uppercase {
  text-transform: uppercase;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fixedLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#ededed, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitButtonStyle {
  margin: 5px;
  width: 250px;
  font-weight: bold;
}

.resetButtonStyle {
  margin-left: 5px;
  margin-top: 5px;
  width: 245px;
  font-weight: bold;
}

.checkBoxStyle {
  min-width: 300px;
}

.payoutAccessFooter {
  position: fixed;
  height: 50px;
  left: 200px;
  right: 0px;
  bottom: 0px;
  background-color: gray;
}

.payoutAccessFooterBottom {
  width: 70%;
  bottom: 14px;
}

.marginTop {
  margin-top: 21px;
}

.marginTopRefreshButton {
  margin-top: 20px;
}

.marginTopRefreshButton23px {
  margin-top: 23px;
}

.ant-badge-status-dot {
  width: 12px;
  height: 12px;
}

.loadingError {
  color: red;
}

.ant-tag {
  margin-right: 1px;
}

.filterButton {
  margin-top: 22px;
  // height: 30px;

  &anticon {
    text-align: center;
  }
}

/// Table styles

.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  width: 20px;
}

.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner {
  margin-left: 3px;
}

.ant-table-fixed-right {
  box-shadow: -8px 0 3px -1px rgba(4, 1, 1, 0.459);
}

.ant-table-filter-dropdown-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 7px 8px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #f0f0f0;
  width: 250px;
}

.ant-table-filter-dropdown-link {
  padding: 10px;
}

// TABLE EXPANDED TABLE

.expand-parent {
  background-color: #b3cccc !important;
}

.columnGroupStyle {
  background-color: #e6e6e6 !important;
}

// Form status styles
.new_form {
  background-color: red;

  &:hover {
    background-color: #ff3333;
  }
}

.edited_form {
  background-color: orange;

  &:hover {
    background-color: #ffb833;
  }
}

// Ant back top styles
.ant-back-top {
  right: 30px;
  bottom: 55px;
}

.ant-back-top-content {
  border-radius: unset;
}

// --- Ant notification ---
.ant-notification {
  width: 100vw;
  margin-right: 16px;
}

.ant-notification-notice {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

// STATISTIC FILTRATION
.statistic-date-filter {
  width: 210px !important;
  display: block !important;
  margin-right: 20px;

  .ant-calendar-range-picker-input {
    width: 42%;
  }

  .ant-calendar-range-picker-input,
  .ant-calendar-picker-input {
    color: #339fdd !important;

    border-color: #d9d9d9 !important;
    border-radius: 3px !important;

    padding-bottom: 1px !important;
  }
}

.timezone-filter {
  .ant-select-selection-selected-value {
    color: #339fdd !important;
  }
}

.timezone-filter-options {
  .ant-select-dropdown-menu-item-active {
    background-color: rgba(51, 159, 221, 0.2) !important;
  }
}

.filters-tabs {
  padding-top: 20px;
  padding-right: 30px;
  padding-bottom: 20px;

  .ant-tabs-tab:hover {
    color: #339fdd !important;
  }

  .ant-tabs-tab-active {
    color: #339fdd !important;
  }

  .ant-tabs-ink-bar {
    background-color: #339fdd !important;
  }
}

.ant-tooltip.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.payout-datepicker {
  .ant-calendar-today-btn {
    display: none;
  }
}

.ant-calendar-picker.invalid .ant-calendar-picker-input {
  border-color: red;
}

.ant-select.invalid .ant-select-selection {
  border-color: red;
}

.ant-spin {
  margin-top: 4px;
}

.ant-menu-inline-collapsed {
  width: 120px !important;
}

.partner-view-checkbox:checked,
.partner-view-checkbox:hover,
.partner-view-checkbox:focus {
  background-image: none !important;
  background-color: white !important;
  border: 1px solid #222849 !important;
}

.create-campaign-modal {
  position: relative;
}

.create-campaign-modal .ant-modal-content {
  padding-left: 50px;
  border-radius: 30px;
}

.create-campaign-modal .ant-modal-content::after {
  content: "";
  position: absolute;
  left: -40px;
  top: -65px;
  width: 125px;
  height: 142px;
  background-image: url("/images/icons/icon-question.svg");
  background-repeat: no-repeat;
}

.create-campaign-modal .anticon {
  display: none;
}

.create-campaign-modal .ant-modal-confirm-title {
  font-size: 25px;
  color: #222849;
  margin-bottom: 15px;
}

.create-campaign-modal .ant-modal-confirm-content {
  font-size: 16px;
  color: #262626;
  margin-left: 0 !important;
  margin-top: 0 !important;
}

.create-campaign-modal .ant-modal-confirm-btns {
  width: 100%;
}

.create-campaign-modal .ant-modal-confirm-btns .ant-btn {
  border: 1px solid #222849;
  border-radius: 30px;
  width: 120px;
  color: #222849;
}

.create-campaign-modal .ant-modal-confirm-btns .ant-btn:hover {
  background-color: #222849;
  color: white;
}

.create-campaign-modal .ant-modal-confirm-btns .ant-btn-primary {
  background-color: #222849;
  color: white;
  width: 120px;
  border: 1px solid transparent;
}

.create-campaign-modal .ant-modal-confirm-btns .ant-btn-primary:hover {
  background-image: linear-gradient(
    90deg,
    rgba(29, 159, 217, 1) 0%,
    rgba(33, 246, 175, 1) 100%
  );
}

.offer-details-description * {
  color: white !important;
}


input::placeholder {
  color: var(--placeholder-color);
  opacity: var(--placeholder-opacity);
  font-size: var(--placeholder-font-size);
  line-height: var(--placeholder-line-height);
  font-weight: var(--placeholder-font-weight);
}

 .inputError {
   @apply text-red border-red;
 }

.pageContent::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px; /* Высота линии */
  background-color: #DEDEDE; /* Цвет линии */
  margin-bottom: 2rem; /* Отступ снизу */
}

.pageContent:last-child::after {
  display: none;
}

@media (min-width: 768px) {
  .pageContent::after {
    display: none;
  }
}