@import '../../../../../sass_modules/all.scss';

.grid {
    width: 100%;
    height: 144px;
    padding: 24px 30px 30px;
    background: $white;
    box-shadow: 0px 0px 2px rgba(13, 34, 66, 0.15);

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 21px;
        height: 27px;

        .date {
            color: $grey_02;
        }

        .status {
            height: 100%;
            font-size: 13px;
            line-height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 10px 5px;
        }
    }

    h4 {
        color: $text;
        margin: 0;
        margin-bottom: 10px;
    }

    p {
        color: $grey_03;
        margin: 0;
    }
}