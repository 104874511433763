.productCard {
    width: 380px;
    max-width: 100%;
    background: #242d47;
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    &__title {
        font-family: 'Open Sans';
        font-size: 18px;
        line-height: 25px;
        color: #f8f8f8;
        text-align: center;
        margin-bottom: 5px;
        min-height: 50px;
    }

    &__description {
        font-size: 15px;
        line-height: 18px;
        color: #959595;
        text-align: center;
        margin: 0 auto;
    }

    &__img {
        margin: 30px auto;
        height: 180px;
    }

    &__table {
        width: 100%;
        margin-bottom: 30px;
        min-height: 164px;
        
        &__row {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding-bottom: 2px;
            border-bottom: 1px dashed rgba(248, 248, 248, .2);
            margin-bottom: 19px;

            &__label, &__value {
                padding: 0;
                margin: 0;
                color: #fff;
                font-size: 16px;
                line-height: 19px;
            }

            &__label {
                font-weight: 300;
                text-align: left;

            }

            &__value {
                font-weight: 400;
                justify-self: end;   
                color: #f8f8f8;
            }
        }
    }

    &__button {
        height: 50px;
        width: 230px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #22b74b;
        color: #22b74b;
        font-size: 16px;
        line-height: 19px;
        transition: opacity .3s ease-in-out;

        &:hover {
            color: #22b74b;
            opacity: .8;
        }
    }
}